v.HorizontalView = class HorizontalView extends v.ScrollEvents{
    
    
    
    constructor(data = {}){
        
        super();
        
        this.prefix = `${this.prop.v.prefix()}view`;

        this.prop = Object.assign(this.prop, {
            selector: `.${this.prefix}`,
            percent: .3,
            load: true,
            loadTimeout: 1000
        });
        
        for(let value in data){
            this.prop[value] = data[value];
        }

        this.targets = [
            'in'
        ];

    }



    check(){

        if(!super.check()){
            return false;
        }

        let checked = this.checkTypes({
            obj: this.prop,
            name: this.name,
            console: this.prop.console,
            vars: [
                ['percent', 'number'],
                ['load', 'boolean'],
                ['loadTimeout', 'integer']
            ]
        });

        if(!checked){
            return false;
        }

        return true;

    }



    setScrollTop(){

        super.setScrollTop();

        if(this.outer === window || this.outer === this.prop.v.document() || this.outer === this.prop.v.body()){
            this.scrollLeft = window.pageXOffset;
        }
        else{
            this.scrollLeft = this.outer.scrollLeft;
        }
        
        return true;

    }



    setEvents(){

        // elements

        for(let i = 0; i < this.elements.length; i++){
            let attribute = this.elements[i].getAttribute(this.data.proceeded);
            if(attribute === null){
                this.elements[i].in = false;
                this.elements[i].out = false;
                this.elements[i].setAttribute(this.data.proceeded, true);
            }
        }

        // outer

        if(this.prop.load & !this.prop.v.loaded()){
            v.addLoad(function(){
                setTimeout(this.setScrollEvent.bind(this), this.prop.loadTimeout);
            }.bind(this));            
        }
        else{
            this.setScrollEvent();
        }
        
        return true;

    }

    setHeight(){

        if(!super.setHeight()){
            return false;
        }

        this.width = this.outer.clientWidth;
        if(this.outer === window || this.outer === this.prop.v.document() || this.outer === this.prop.v.body()){
            this.width = this.prop.v.vw()[1];
        }

        this.percent = this.prop.percent * this.width;
        
        return true;

    }



    seek(){

        if(!super.seek()){
            return false;
        }
        
        let currentPercent = this.percent;
        if(this.firstLoad){
            currentPercent = this.width;
        }

		for(let i = 0; i < this.elements.length; i++){

            let bounding = this.elements[i].getBoundingClientRect();
            
			if(
                (bounding.left <= currentPercent & !this.elements[i].out)
                ||
                (bounding.right >= currentPercent & this.elements[i].out)
            ){
                
                // in view

				if(bounding.left >= this.elements[i].clientWidth * -1){

					if(this.elements[i].in){
                        continue;
                    }

                    this.launchEventsByTarget("in", {
                        in: true,
                        element: this.elements[i]
                    });

					this.elements[i].in = true;
                    this.elements[i].out = false;
                    
                }
                
            }
            
        }

        return true;

    }

    
    
    init(){
        
        this.percent = 0;

        if(!super.init()){
            return false;
        }

        this.add({
            target: 'in',
            do: function(data){
                data.element.classList.add(`${this.prefix}ed`);
            }.bind(this)
        });

        return {
            getElements: () => this.elements,
            getOuter: () => this.outer,
            seek: this.seek.bind(this),
            add: this.add.bind(this),
            delete: this.delete.bind(this),
            turn: this.turn.bind(this),
            events: () => this.events,
            prop: () => this.prop,
            changeProp: this.changeProp.bind(this)
        };

    }



}